<template>
  <v-expansion-panel v-show="$SHOW_PRICING_FOR(role, $store.state.user.role)">
    <v-expansion-panel-header>
      <div class="caption font-weight-bold" style="opacity: .8;width: calc(100% - 120px);">
        {{ title }}
      </div>
      <div class="text-right pr-2" style="width: 120px;" @click.stop>
        <v-btn depressed small class="inline" :disabled="!show" @click.prevent="AddNewData()">
          +
        </v-btn>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="!updatedData.length" class="text-center grey--text caption">
        No data
      </div>
      <template v-for="(dgp, idgp) in updatedData">
        <v-row :key="'dgp-agen-' + idgp" v-if="dgp.for_role === role" no-gutters>
          <v-col cols="6" class="col-md-3 pr-1 global-pricing-list">
            <span class="global-pricing-delete" @click.prevent="RemoveData(dgp, idgp)">
              x
            </span>
            <v-select
              v-model="updatedData[idgp].type"
              label="Type"
              item-value="value"
              item-text="name"
              :name="Math.random()"
              autocomplete="off"
              :items="[{ value: 'price', name: 'Price' }, { value: 'percent', name: 'Percent' }]"
              outlined
              dense
              hide-details
              class="mb-3 body-2"
            />
          </v-col>
          <v-col cols="6" class="col-md-3 pr-lg-1">
            <v-text-field
              v-if="dgp.type === 'percent'"
              v-model.number="updatedData[idgp].value_discount"
              label="Discount"
              outlined
              :name="Math.random()"
              autocomplete="off"
              suffix="%"
              block
              dense
              hide-details
              class="body-2"
            />
            <Currency
              v-else-if="dgp.type === 'price'"
              v-model.number="updatedData[idgp].value_discount"
              label="Discount"
              prefix="Rp"
            />
            <!-- <v-text-field
              v-model.number="updatedData[idgp].value_discount"
              label="Discount"
              outlined
              :name="Math.random()"
              autocomplete="off"
              :prefix="dgp.type === 'price' ? 'Rp' : ''"
              :suffix="dgp.type === 'percent' ? '%' : ''"
              block
              dense
              hide-details
              class="body-2"
            /> -->
          </v-col>
          <v-col cols="6" class="col-md-3 pr-1">
            <Currency
              v-model.number="updatedData[idgp].value_minimum"
              label="Minimur Order"
            />
            <!-- <v-text-field
              v-model.number="updatedData[idgp].value_minimum"
              label="Minimur Order"
              outlined
              block
              :name="Math.random()"
              autocomplete="off"
              dense
              hide-details
              class="body-2"
            /> -->
          </v-col>
          <v-col cols="6" class="col-md-3">
            <v-select
              v-model.number="updatedData[idgp].first_order_only"
              label="Option"
              item-value="value"
              item-text="name"
              :name="Math.random()"
              autocomplete="off"
              :items="[{ value: 1, name: 'First Order Only' }, { value: 0, name: 'Any Order' }]"
              outlined
              dense
              hide-details
              class="body-2"
            />
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    updatedData: []
  }),
  watch: {
    data: function (v) {
      this.updatedData = [].concat(v).map(r => {
        if (r.value_discount) {
          r.value_discount = parseFloat(r.value_discount)
        }
        return r
      })
    }
  },
  created () {
    this.updatedData = [].concat(this.data).map(r => {
      if (r.value_discount) {
        r.value_discount = parseFloat(r.value_discount)
      }
      return r
    })
  },
  methods: {
    AddNewData () {
      this.$emit('update', {
        act: 'add',
        data: {
          id: null,
          for_role: this.role,
          type: 'percent',
          user: parseInt(this.$store.state.user.id),
          value_discount: 20,
          value_minimum: 0,
          first_order_only: 1
        }
      })
    },
    RemoveData (dgp, i) {
      this.$emit('update', {
        act: 'delete',
        index: i,
        data: Object.assign(dgp)
      })
    }
  }
}
</script>

<style lang="scss">
.global-pricing-list {
  position: relative;
  .global-pricing-delete {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff0000;
    cursor: pointer;
    color: #fff;
    padding: 2px 0px;
    text-align: center;
    line-height: 100%;
    z-index: 1;
  }
}
</style>
